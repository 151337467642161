import background from '../img/concerts_bg.jpg'
import aboutImg from '../img/concert.png'

function Concerts() {
  return (
    <div class="about-box" style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'repeat-y', backgroundSize: '100%', backgroundPosition: 'center' }}>
      <div class="about-column-text">
        <h2>Termine</h2>        

        <div class="concert">        
          <h3>31.01.2023<br/>Offene Bühne in Ahrensburg</h3>          
          <a href="https://catharina-mit-c.de/offene-buehne-in-ahrensburg/" target="_BLANK" rel="noreferrer">Link zur Veranstaltung</a> 
        </div>

        <div class="concert">        
          <h3>22.06.2023<br/>Private Veranstaltung</h3>
        </div>
        
      </div>
      <div class="about-column-image">
        <img src={aboutImg} alt="Küstenspacken machen Musik" class="center" />
      </div>
    </div>
  )
}

export default Concerts

/*
Beispiel eintrag:

<div class="concert">        
          <h3>29.06.2021 - 17:00h <br/> Open Stage - Pelzerhaken</h3>
          <a href="https://www.luebecker-bucht-ostsee.de/veranstaltungsdetails/details/99564/stammdaten/open-stage-pelzerhaken" target="_BLANK" rel="noreferrer">Mehr Infos</a>
        </div>*/